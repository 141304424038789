import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import { PageContent } from '../components/PageContent'
import Container from '../components/Container'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'
import { places } from '../content/content'
import { StaticImage } from "gatsby-plugin-image"

const StyledContainer = styled(Container)`
    padding-top: 3rem;
    padding-bottom: 5rem;
`

const GalleryWrapper = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;

    @media (min-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem 2rem;
    }

    @media ${breakpoint.md} {
        gap: 4rem 3rem;
    }

    @media ${breakpoint.lg} {
        grid-template-columns: repeat(3, 1fr);
    }
`

const GalleryItem = styled.li`
    border-bottom: 2px solid black;
`

const GalleryItemBottom = styled.div`
    padding: 1rem;
`

const Title = styled.h2`
    margin: 0.5em 0;

    @media ${breakpoint.md} {
        margin: 0.75em 0;
    }
`

const Gallery = () => {
    return (
        <>
            <SmallBanner title="Místa, která jsme navštívily" isLongTitle={true}/>
            <PageContent>
                <StyledContainer>
                    <GalleryWrapper>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/england_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.england.title}</Title>
                                <p>{places.england.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/belgium_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.belgium.title}</Title>
                                <p>{places.belgium.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/bulgaria_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.bulgaria.title}</Title>
                                <p>{places.bulgaria.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/cambodia_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.cambodia.title}</Title>
                                <p>{places.cambodia.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/crete_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.crete.title}</Title>
                                <p>{places.crete.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/cuba_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.cuba.title}</Title>
                                <p>{places.cuba.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/denmark_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.denmark.title}</Title>
                                <p>{places.denmark.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/france_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.france.title}</Title>
                                <p>{places.france.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/germany_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.germany.title}</Title>
                                <p>{places.germany.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/holand_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.holand.title}</Title>
                                <p>{places.holand.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/croatia_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.croatia.title}</Title>
                                <p>{places.croatia.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/italy_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.italy.title}</Title>
                                <p>{places.italy.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/israel_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.israel.title}</Title>
                                <p>{places.israel.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/jordan_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.jordan.title}</Title>
                                <p>{places.jordan.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/latvia_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.latvia.title}</Title>
                                <p>{places.latvia.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/lithuania_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.lithuania.title}</Title>
                                <p>{places.lithuania.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/hungary_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.hungary.title}</Title>
                                <p>{places.hungary.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/morocco_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.morocco.title}</Title>
                                <p>{places.morocco.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/norway_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.norway.title}</Title>
                                <p>{places.norway.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/poland_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.poland.title}</Title>
                                <p>{places.poland.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/portugal_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.portugal.title}</Title>
                                <p>{places.portugal.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/austria_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.austria.title}</Title>
                                <p>{places.austria.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/rhodes_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.rhodes.title}</Title>
                                <p>{places.rhodes.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/northernIreland_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.northernIreland.title}</Title>
                                <p>{places.northernIreland.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/northMacedonia_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.northMacedonia.title}</Title>
                                <p>{places.northMacedonia.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/scotland_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.scotland.title}</Title>
                                <p>{places.scotland.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/slovakia_1.jpeg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.slovakia.title}</Title>
                                <p>{places.slovakia.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/serbia_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.serbia.title}</Title>
                                <p>{places.serbia.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/sriLanka_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.sriLanka.title}</Title>
                                <p>{places.sriLanka.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/spain_5.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.spain.title}</Title>
                                <p>{places.spain.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/thailand_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.thailand.title}</Title>
                                <p>{places.thailand.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/turkey_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.turkey.title}</Title>
                                <p>{places.turkey.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/ukraine_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.ukraine.title}</Title>
                                <p>{places.ukraine.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/usaEast_1.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.usaEast.title}</Title>
                                <p>{places.usaEast.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                        <GalleryItem>
                            <StaticImage src="../assets/images/destinations/wales_2.jpg" alt=""></StaticImage>
                            <GalleryItemBottom>
                                <Title>{places.wales.title}</Title>
                                <p>{places.wales.description}</p>
                            </GalleryItemBottom>
                        </GalleryItem>
                    </GalleryWrapper>
                </StyledContainer>
            </PageContent>

        </>
    )
}

export default Gallery

export const Head = () => (
    <>
      <title>Místa, která jsem navštívila | Cestu-j</title>
      <meta name="description" content="Cestovní itineráře na míru" />
    </>
  )